export const Tabs = {
  MAIN: 'MAIN',
  DOLLAR: 'DOLLAR',
  CURRENCIES: 'CURRENCIES',
  GOVERNORATE_CURRENCIES: 'GOVERNORATE_CURRENCIES',
  ARABIC_CURRENCIES: 'ARABIC_CURRENCIES',
  FOREIGN_CURRENCIES: 'FOREIGN_CURRENCIES',
  GOLD: 'GOLD',
  MATERIALS: 'MATERIALS',
  NEWS: 'NEWS',
};

export const TabsNames = {
  MAIN: 'الرئيسية',
  DOLLAR: 'الدولار',
  CURRENCIES: 'العملات',
  GOVERNORATE_CURRENCIES: 'المحافظات',
  ARABIC_CURRENCIES: 'العملات العربية',
  FOREIGN_CURRENCIES: 'العملات الأجنبية',
  GOLD: 'الذهب',
  MATERIALS: 'بورصات',
  NEWS: 'الأخبار',
};

export const SiteLocations = {
  TRANSFER: 1,
  GOVERNORATE_CURRENCIES: 2,
  ARABIC_CURRENCIES: 3,
  FOREIGN_CURRENCIES: 4,
  GOLD: 5,
  MATERIALS: 6,
};

export const AppLocations = {
  DOLLAR: 7,
  CURRENCIES: 8,
  GOLD: 9,
  MATERIALS: 10,
};

export const aboutusContent = `
تطبيق أسعار الصرف في سوريا الأصلي.
هو أول تطبيق يقوم بتزويد السوريين بمتغيرات أسعار الصرف وأنواعها المختلفة.
كسعر المصرف المركزي
أسعار صرف الحوالات الخارجية
وسعر السوق ..الخ
كذلك طورنا خدمة المقالات التي تشرح أنواع الاسعار مرفقة بملخصات الأخبار حول سعر الصرف وحركة البورصة السورية.
يدار التطبيق من قبل مجموعة من الشباب السوريين، المختصصين في العلوم الاقتصادية ومطوري البرامج والتكنولوجيا
`;