import styled, { createGlobalStyle } from 'styled-components';
import { Link as gatsbyLink } from 'gatsby';
import DroidKufiRegular from '@fonts/DroidKufi-Regular.ttf';
import DroidKufiBold from '@fonts/DroidKufi-Bold.ttf';
import RobotoThin from '@fonts/Roboto-Thin.ttf';
import RobotoLight from '@fonts/Roboto-Light.ttf';
import RobotoRegular from '@fonts/Roboto-Regular.ttf';
import RobotoMedium from '@fonts/Roboto-Medium.ttf';
import RobotoBold from '@fonts/Roboto-Bold.ttf';
import RobotoBlack from '@fonts/Roboto-Black.ttf';

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 400;
  src: url(${DroidKufiRegular}) format('truetype');
}
@font-face {
  font-family: 'Droid Arabic Kufi';
  font-style: normal;
  font-weight: 600;
  src: url(${DroidKufiBold}) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  src: url(${RobotoThin}) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(${RobotoLight}) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(${RobotoRegular}) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(${RobotoMedium}) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url(${RobotoBold}) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(${RobotoBlack}) format('truetype');
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font: 62.5% open-sans;
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
}
body {
  box-sizing: border-box;
  background-color: ${props => props.theme.bgColor};
  color: hsla(0, 0%, 0%, 0.8);
  color: ${props => props.theme.txtColor};
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 0;
  word-wrap: break-word;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: ${props => (props.showMobMenu ? 'hidden' : 'initial')};
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Droid Arabic Kufi', Arial, Helvetica, sans-serif;
}
.popup-content {
  font-family: ${props => props.theme.fonts.arabicFont};
  font-size: 14px;
  text-align: center;
}
.swal-button {
  font-family: ${props => props.theme.fonts.arabicFont};
}
`;

export const InfoBox = styled.div`
  margin: 0 auto;
  min-height: 300px;
  text-align: center;
  padding: 24px;
  font-family: ${props => props.theme.fonts.arabicFont};

  #en {
    text-align: left;
    font-family: ${props => props.theme.fonts.englishFont};

    h1, h2, h3, h4, h5 {
      font-size: 16px;
    }
  }

  #ar {
    direction: rtl;
    text-align: right;

    h1, h2, h3, h4, h5 {
      font-size: 16px;
    }
  }
  
  p, ul {
    font-size: 16px;
  }
`;

export const Button = styled.button`
  min-width: 100px;
  font-family: ${props => props.theme.fonts.arabicFont};
  font-weight: 600;
  border: 2px solid ${props => props.theme.blueColor};
  border-radius: 4px;
  color: ${props => props.theme.blueColor};
  background-color: ${props => props.theme.lightBackgroundColor};
  cursor: pointer;
  padding: 4px 8px;
  transition: all 0.5s ease;
  :hover {
    color: ${props => props.theme.textColor};
    background-color: ${props => props.theme.blueColor};
  }
`;

export const Logo = styled(gatsbyLink)`
  display: flex;
  height: 64px;
  text-decoration: none;
  color: ${props => props.theme.textColor};

  @media (min-width: 1200px) {
    margin-right: 64px;
  }
`;

export const LogoContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  align-items: flex-end;
  justify-content: center;

  h2 {
    margin: 0;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 16px;

    &:first-child {
      font-family: 'Droid Arabic Kufi', Arial, Helvetica, sans-serif;
    }
  }
  p {
    direction: rtl;
    font-family: ${props => props.theme.fonts.arabicFont};
    font-size: 14px;
    text-align: right;
    max-width: 600px;
  }
`;

export const LogoImage = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 8px;
  @media (min-width: 920px) {
    margin-right: 0;
  }
`;

export const Navigation = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  flex-direction: column-reverse;
  padding: 56px 0 12px;
  align-items: center;
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  display: flex;
  opacity: ${props => (props.active ? '1' : '0')};
  font-family: ${props => props.lang == 'en' ? props.theme.fonts.englishFont : props.theme.fonts.arabicFont};
  background-color: ${props => props.theme.backgroundColor};
  transition: all 0.4s ease;
  width: 100%;
  
  @media (min-width: 992px) {
    flex-direction: row;
    width: inherit;
    opacity: 1;
    visibility: visible;
    margin-left: 64px;
    padding: 0;
    position: relative;
  }
  
  ul {
    display: flex;
    text-align: center;
    margin: 0px;
    padding: 0;
    
    li {
      display: inline-block;
      font-size: 16px;

      a, button {
        text-decoration: none;
        font-weight: 600;
        background-color: transparent;
        border-radius: 4px;
        transition: color 0.4s ease, background-color 0.4s ease;
        margin: 0 2px;
        font-family: ${props => props.theme.fonts.arabicFont};

        :hover {
          background-color: ${props => props.theme.textGold};
          color: ${props => props.theme.textColorBlack};
        }
      }
    }
  }
  .social {
      margin-right: 20px;

      li {
        a {
          background-color: ${props => props.theme.darkBackgroundColor};
          color: ${props => props.theme.textColor};
          width: 24px;
          height: 24px;
          display: block;
          border-radius: 18px;
          padding: 6px;
        }
      }
    }
`;

export const ButtonLink = styled.button`
  border: 0;
  font-size: 16px;
  color: ${props => props.theme.white};
  padding: 10px 15px;
  display: block;
  background-color: transparent;
  display: inline-block;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  backface-visibility: hidden;
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    border-radius: 4px;
  }
`;

export const Link = styled(gatsbyLink)`
  color: ${props => props.theme.white};
  padding: 10px 15px;
  display: block;
  background-color: transparent;
  display: inline-block;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  backface-visibility: hidden;
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    border-radius: 4px;
  }
`;
