import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
  position: relative;

  nav {
    z-index: 99;
  }
  
  @media (min-width: 992px) {
    padding: 20px 0;
  }
`;

export const MobileMenu = styled.button`
  position: relative;
  border: 0;
  margin: 16px 0 0 16px;
  padding: 0;
  background-color: transparent;
  color: ${props => props.theme.textColor};
  font-size: 32px;
  height: 32px;
  z-index: 100;

  @media (min-width: 992px) {
    display: none;
  }
`;