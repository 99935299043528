import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaTelegramPlane } from '@react-icons/all-files/fa/FaTelegramPlane';
import { FiMenu } from '@react-icons/all-files/fi/FiMenu';
import { FiX } from '@react-icons/all-files/fi/FiX';
import { resetUrl } from '@utils/helprs';
import { aboutusContent } from '@utils/consts';
import {
  Link,
  ButtonLink,
  Logo,
  LogoContent,
  LogoImage,
  Navigation,
} from '@components/styledComponents/index';
import { Container, MobileMenu } from './styles';

const showAboutus = () => {
  swal({
    content: <div className="popup-content">{aboutusContent}</div>,
    button: 'إغلاق',
  });
};

const Header = () => {
  const [activeNav, toggleNav] = useState(false);
  return (
    <Container>
      <MobileMenu onClick={() => toggleNav(val => !val)}>
        {activeNav ? <FiX /> : <FiMenu />}
      </MobileMenu>
      <Navigation active={activeNav}>
        <ul className="social">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/syriastockss"
            >
              <FaTelegramPlane />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/syrianexchangeprices"
            >
              <FaFacebookF />
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <ButtonLink href="mailto:syriastocks@gmail.com">تواصل معنا</ButtonLink>
          </li>
          <li>
            <Link to="/#news" onClick={() => resetUrl()}>الأخبار</Link>
          </li>
          <li>
            <ButtonLink onClick={() => showAboutus()}>من نحن</ButtonLink>
          </li>
        </ul>
      </Navigation>
      <Logo to="/">
        <LogoContent>
          <h2>أسعار الصرف السورية</h2>
          <h2>Syrian Exchange Prices</h2>
        </LogoContent>
        <LogoImage>
          <StaticImage
            src="./logo-rounded.png"
            alt="Logo"
            quality={95}
            width={64}
          />
        </LogoImage>
      </Logo>
    </Container>
  );
};
export default Header;
