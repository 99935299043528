import React from 'react';
import Arrow from '@images/arrow-up.svg';
import { ScrollButton } from './styles';

const ScrollTopButton = (props) => {
  return (
    <ScrollButton active={props.active} onClick={() => props.onClick()}>
      <img src={Arrow} />
    </ScrollButton>
  );
}

export default ScrollTopButton;