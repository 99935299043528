import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};

  .top-container {
    flex-direction: column;
    padding: 10px 0 20px;

    display: flex;
    justify-content: space-between;
  }

  .logo-container {
    height: auto;
    .logo-image {
      height: 140px;
      width: 140px;
    }
  }

  nav {
    position: relative;
    visibility: visible;
    opacity: 1;
    flex-direction: column-reverse;
    padding: 10px 0 40px;
    align-items: center;


    ul {
      flex-direction: column-reverse;
      margin: 0 40px;
      align-items: center;

      li {
        font-size: 14px;
        a {
          margin: 2px 0;
          min-width: 80px;
        }
      }
      &.social {
        margin-top: 10px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0;

        li {
          a {
            min-width: initial;
            margin: 0 2px;
          }
        }
      }
    }

    .social-box {
      text-align: center;
    }

    .social-title {
      font-family: ${props => props.theme.fonts.arabicFont};
      font-size: 16px;
      font-weight: 600;
      margin: 8px 0 10px;
      text-align: center;
    }
  }
  @media (min-width: 992px) {
    .top-container {
      flex-direction: row;
      padding-top: 20px;
    }
    nav {
      flex-direction: row;
      padding-top: 0px;
      padding-bottom: 40px;
      flex-direction: row;
      align-items: flex-start;
    }
    .logo-container {
      .logo-image {
        height: 140px;
        width: 140px;
      }
    }
  }
`;

export const BottomFooter = styled.div`
  background-color: ${props => props.theme.darkBackgroundColor};
  text-align: center;
  font-family: ${props => props.theme.fonts.arabicFont};
  direction: rtl;
`;
