import React from 'react';
import { } from './styles';

const Preloader = ({ show }) => {
  if (show) {
    return (
      <div>Preloader</div>
    );
  }
  return <></>;
}

export default Preloader;