import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF';
import { FaTelegramPlane } from '@react-icons/all-files/fa/FaTelegramPlane';
import swal from '@sweetalert/with-react';
import { aboutusContent } from '@utils/consts';
import { resetUrl } from '@utils/helprs';
import {
  ButtonLink,
  Link,
  Logo,
  LogoContent,
  LogoImage,
  Navigation,
} from '@components/styledComponents/index';
import { BottomFooter, Container } from './styles';

const showAboutus = () => {
  swal({
    content: <div className="popup-content">{aboutusContent}</div>,
    button: 'إغلاق',
  });
};

const Footer = () => (
  <Container>
    <div className="top-container">
      <Navigation>
        <div className="social-box">
          <div className="social-title">تابعونا على</div>
          <ul className="social">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://t.me/syriastockss"
              >
                <FaTelegramPlane />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/syrianexchangeprices"
              >
                <FaFacebookF />
              </a>
            </li>
          </ul>
        </div>
        <ul>
          <li>
            <Link to="/privacy-policy" onClick={() => resetUrl()}>سياسة الخصوصية</Link>
          </li>
          <li>
            <ButtonLink href="mailto:syriastocks@gmail.com">تواصل معنا</ButtonLink>
          </li>
          <li>
            <Link to="/#news" onClick={() => resetUrl()}>الأخبار</Link>
          </li>
          <li>
            <ButtonLink onClick={() => showAboutus()}>من نحن</ButtonLink>
          </li>
        </ul>
      </Navigation>
      <Logo className="logo-container" to="/">
        <LogoContent>
          <h2>أسعار الصرف السورية</h2>
          <h2>Syrian Exchange Prices</h2>
          <p>
            تطبيق أسعار الصرف في سوريا الأصلي.
            <br />
            هو أول تطبيق يقوم بتزويد السوريين بمتغيرات أسعار الصرف وأنواعها
            المختلفة.
            <br />
            مثل أسعار المصرف المركزي وأسعار صرف الحوالات الخارجية وسعر السوق …
            <br />
            قسم الأخبار يقدم مقالات عن أنواع الاسعار المرفقة وملخصات الأخبار
            حول سعر الصرف وحركة البورصة السورية. يدار التطبيق من قبل مجموعة من
            الشباب السوريين، المختصصين في العلوم الاقتصادية ومطوري البرامج
            والتكنولوجيا.
          </p>
        </LogoContent>
        <LogoImage className="logo-image">
          <StaticImage
            src="./logo-rounded.png"
            alt="Logo"
            quality={95}
            width={140}
          />
        </LogoImage>
      </Logo>
    </div>
    <BottomFooter>
      <p>جميع الحقوق المحفوظة لـ موقع أسعار الصرف السورية {new Date().getFullYear()} &copy;</p>
    </BottomFooter>
  </Container>
);

export default Footer;
