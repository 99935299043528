import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '@styledComponents/index';
import { darkTheme, defaultTheme } from '@styledComponents/theme';
import ScrollTopButton from '@components/scroll-top-button/index';
import Preloader from '@components/preloader/index';
import AppContext from '@utils/app-context';
import { sendToTop, debounce } from '@utils/helprs';

import Header from './header/index';
import Footer from './footer/index';

const toggleTheme = setTheme => {
  return () => {
    setTheme(theme => (theme === defaultTheme ? darkTheme : defaultTheme));
  };
};

const showPreloader = setShow => {
  return status => {
    setShow(status);
  };
};

function onScroll($html, setShowScrollTopBtn) {
  if ($html.scrollTop > 180) {
    setShowScrollTopBtn(true);
  } else {
    setShowScrollTopBtn(false);
  }
}

const Layout = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);
  const [loading, setLoading] = useState(false);
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);
  const [showMobMenu, setShowMobMenu] = useState(false);
  const [size, setSize] = useState(
    typeof window !== 'undefined'
      ? {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      : {
          width: 0,
          height: 0,
        }
  );

  useEffect(() => {
    const $html = document.querySelector('html');
    function handelScrolling() {
      onScroll($html, setShowScrollTopBtn);
    }
    function handleResize() {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    const debouncedHandleResize = debounce(handleResize, 500);

    window.document.addEventListener('scroll', handelScrolling);
    window.addEventListener('resize', debouncedHandleResize);

    handelScrolling();
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      window.document.removeEventListener('scroll', handelScrolling);
    };
  }, []);

  return (
    <>
      <AppContext.Provider
        value={{
          toggleTheme: toggleTheme(setTheme),
          showPreloader: showPreloader(setLoading),
          setShowMobMenu,
          showMobMenu,
          size,
        }}
      >
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle showMobMenu={showMobMenu} />
            <Header theme={theme} />
            <main>{children}</main>
            <ScrollTopButton active={showScrollTopBtn} onClick={sendToTop} />
            <Footer />
            <Preloader show={loading} />
          </>
        </ThemeProvider>
      </AppContext.Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
