import { VALUE_STATUS } from '@components/coin-block/defines';

export function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export function sendToTop() {
  const scrollerComponent = document.querySelector('html');
  if (!scrollerComponent) {
    return;
  }
  const duration = 300;
  if (scrollerComponent.scrollTop === 0) return;

  const totalScrollDistance = scrollerComponent.scrollTop;
  let scrollY = totalScrollDistance;
  let oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      scrollY -=
        (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration;
      if (scrollY <= 0) return (scrollerComponent.scrollTop = 0);
      scrollerComponent.scrollTop = scrollY;
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

export const getStatusValue = (preValue, value) => {
  if (preValue > value) {
    return VALUE_STATUS.DOWN;
  }
  if (preValue < value) {
    return VALUE_STATUS.UP;
  }
  return VALUE_STATUS.NONE;
};

export const getStatusClass = (preValue, value) => {
  if (preValue > value) {
    return 'price-down';
  }
  if (preValue < value) {
    return 'price-up';
  }
  return 'price-minus';
};

export const getSlug = title => {
  return encodeURIComponent(title.toLowerCase().replace(/ /g, '-'));
};

export const resetUrl = () => {
  setTimeout(() => {
    history.replaceState(null, null, ' ');
  }, 500);
};