const primaryColor = '#27ae61';
const secondaryColor = '#e84c3d';

const goldColor = '#ffc400';
const darkGoldColor = '#7f6200';

const blackColor = '#2a2b2f';
const lighBlackColor = '#2b2c30';
const darkBlackColor = '#25262a';

const whiteColor = '#ffffff';
const blueColor = '#1bacd8';
const grayColor = '#eeeeee';

export const defaultTheme = {
  primaryColor,
  secondaryColor,
  goldColor,
  darkBlackColor,
  white: whiteColor,
  blueColor,
  lighBlackColor,
  backgroundColor: blackColor,
  darkBackgroundColor: darkBlackColor,
  lightDarkBackgroundColor: lighBlackColor,
  lightBackgroundColor: whiteColor,
  grayBackgroundColor: grayColor,
  borderBlockColor: '#51565a',
  textColor: whiteColor,
  textColorBlack: blackColor,
  lightTextColor: lighBlackColor,
  textGold: goldColor,
  textDarkGold: darkGoldColor,
  headingTxtGreen: primaryColor,
  headingTxtGold: goldColor,
  downValueColor: secondaryColor,
  upValueColor: primaryColor,
  stableValueColor: '#565b61',
  tabSelectedBg: blueColor,
  underlineColor: blueColor,
  fonts: {
    arabicFont: `'Droid Arabic Kufi', Arial, Helvetica, sans-serif`,
    englishFont: `'Roboto', Arial, Helvetica, sans-serif`
  }
};

export const darkTheme = {
  ...defaultTheme,
};
