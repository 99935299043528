export const COIN_TYPE = {
  BLUE: 1,
  RED: 2,
  GOLD: 3,
  BLACK: 4,
};

export const VALUE_STATUS = {
  UP: 1,
  DOWN: 2,
  NONE: 3,
};

export const BLOCK_SIZE = {
  REGULAR: 1,
  WIDE: 2,
};

export const COIN_TYPE_QUERY = {
  COIN: 'coin',
  GOLD: 'gold',
  MATERIAL: 'material'
};
