import styled from 'styled-components';

export const ScrollButton = styled.div`
  bottom: 80px;
  border-radius: 30px;
  padding: 10px;
  position: fixed;
  right: 16px;
  z-index: 100;
  cursor: pointer;
  transition: visibility 0.6s ease, opacity 0.6s ease;
  visibility: ${(props) => props.active ? 'visible' : 'hidden'};
  opacity: ${(props) => props.active ? '1' : '0'};
  background-color: ${(props) => props.theme.blueColor};
  width: 18px;
  height: 18px;
  img {
    width: 18px;
    height: 18px;
  }
`;